import React, { createContext, useState, useEffect } from 'react'
import { me } from 'services'
import { User, UserPermission } from 'models'

interface UserContextType {
	user: User
	isSuperAdmin: boolean
	canEditWarrantyStartDate: boolean
	withPermissionCheck: (action: any, itemId: string) => any
	canUserEditProject: (itemId: string) => boolean
	projectSearchContent: string
	setProjectSearchContent: (string: string) => void
}

export const UserContext = createContext<UserContextType>({
	user: null,
	isSuperAdmin: false,
	canEditWarrantyStartDate: false,
	withPermissionCheck: () => {},
	canUserEditProject: () => false,
	projectSearchContent: '',
	setProjectSearchContent: async () => {
		throw new Error('setProjectSearchContent method not implemented')
	}
})

export const UserContextProvider = ({ children }: any) => {
	const [user, setUser] = useState<User>(null)
	const [canEditWarrantyStartDate, setCanWarrantyStartDate] =
		useState<boolean>(false)
	const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false)
	const [projectSearchContent, setProjectSearchContent] = useState('')

	const canUserEditProject = (itemId: string) => {
		const isAllowed =
			user?.permission?.includes(UserPermission.SuperAdmin) ||
			user?.permission?.includes(UserPermission.ProjectManager) ||
			user?.id.toString() === itemId?.toString()
		return isAllowed
	}

	const withPermissionCheck = (action: () => void, itemId: string) => {
		return () => {
			if (canUserEditProject(itemId)) {
				action()
			} else {
				alert('You do not have permission to perform this action.')
			}
		}
	}

	useEffect(() => {
		const fetchUser = async () => {
			const { data } = await me()
			setUser(data)
			setCanWarrantyStartDate(
				data?.permission?.includes(UserPermission.EditWarrantyStartDate)
			)
			setIsSuperAdmin(data?.permission?.includes(UserPermission.SuperAdmin))
		}
		fetchUser()
	}, [])

	return (
		<UserContext.Provider
			value={{
				user,
				isSuperAdmin,
				canEditWarrantyStartDate,
				withPermissionCheck,
				canUserEditProject,
				projectSearchContent,
				setProjectSearchContent
			}}
		>
			{children}
		</UserContext.Provider>
	)
}
