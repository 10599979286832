export const validateForm = (fields: any, callback: (errors: any) => void) => {
	const newErrors = {} as any
	Object.keys(fields).forEach(key => {
		const fieldKey = key as keyof typeof fields
		if (fieldKey === 'paymentTermsId' && fields[fieldKey] === 1) {
			newErrors[fieldKey] = 'This field is required'
		}
		if (
			fields[fieldKey] === undefined ||
			fields[fieldKey] === null ||
			!Boolean(fields[fieldKey].toString().trim())
		) {
			newErrors[fieldKey] = 'This field is required'
		}
	})
	const isValid = Object.keys(newErrors).length === 0
	if (!isValid) {
		callback(newErrors)
	}
	return isValid
}
