export const parseAddress = (address: string, type: 'pdf' | 'form') => {
	if (address) {
		const regex =
			/(.+?)\s*,?\s*(AB|BC|MB|NB|NL|NT|NS|NU|ON|PE|QC|SK|YT)\s*,?\s*([A-Z]\d[A-Z]\s?\d[A-Z]\d)/
		const match = address.match(regex)
		if (match) {
			if (type === 'pdf') return [match[1], `${match[2]}, ${match[3]}`]
			if (type === 'form') return [match[1], match[2], match[3]]
		}
	}

	return [address]
}

export const findProvinceInAddress = (address: string) => {
	if (address) {
		const regex = /\b(AB|BC|MB|NB|NL|NT|NS|NU|ON|PE|QC|SK|YT)\b/
		const match = address.match(regex)
		if (match) {
			return match[0]
		}
	}
	return ''
}
