import axios from 'axios'
import { LightingExchangeCreateState } from 'models'

export const getLightingOptions = async () => {
	try {
		const response = await axios.get(`/lighting-exchanges/options`)
		return response
	} catch (error: any) {
		return error.response
	}
}

export const getLightingExchangeById = async (lightingExchangeId: string) => {
	try {
		const response = await axios.get(
			`/lighting-exchanges/${lightingExchangeId}`
		)
		return response
	} catch (error: any) {
		return error.response
	}
}

export const createLightingExchange = async (
	projectId: string,
	newExchange: LightingExchangeCreateState
) => {
	try {
		const response = await axios.post(`/lighting-exchanges`, {
			projectId,
			newExchange
		})
		return response
	} catch (error: any) {
		return error.response
	}
}

export const cloneLightingExchange = async (
	lightingExchangeIds: string[],
	newSectionName: string
) => {
	try {
		const response = await axios.post(`/lighting-exchanges/clone`, {
			lightingExchangeIds,
			newSectionName
		})
		return response
	} catch (error: any) {
		return error.response
	}
}

export const deleteLightingExchange = async (lightingExchangeId: string) => {
	try {
		const response = await axios.delete(
			`/lighting-exchanges/${lightingExchangeId}`
		)
		return response
	} catch (error: any) {
		return error.response
	}
}

export const updateLightingExchange = async (
	lightingExchangeId: any,
	exchangeData: any
) => {
	try {
		const response = await axios.put(
			`/lighting-exchanges/${lightingExchangeId}`,
			{ ...exchangeData }
		)
		return response
	} catch (error: any) {
		return error.response
	}
}
